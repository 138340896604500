import React from 'react'

import { VisuallyHidden } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="VisuallyHidden"
    components={[{ component: VisuallyHidden }]}
  >
    <Section>
      <Playground>
        <VisuallyHidden>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
        </VisuallyHidden>
      </Playground>
      <Playground format="html">
        <div className="visually-hidden">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
        </div>
      </Playground>
    </Section>
  </Content>
)

export default Page
